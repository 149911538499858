import { type UseQueryOptions } from "@tanstack/react-query";

import { getAllScreenerLocations } from "@/app/api/screeners";
import { useAuthedQuery } from "@/hooks/queries/use-authed-query.hook";
import type { AllLocationsResponse } from "@/types/screeners";

const SCREENER_LOCATIONS_QUERY_KEY_BASE = "screener_location";
const SCREENER_LOCATION_QUERY_KEYS = {
  base: [SCREENER_LOCATIONS_QUERY_KEY_BASE] as const,
};

export const useAllScreenerLocationsQuery = (
  config: Partial<UseQueryOptions<AllLocationsResponse>> = {},
) => {
  return useAuthedQuery({
    queryKey: SCREENER_LOCATION_QUERY_KEYS.base,
    queryFn: getAllScreenerLocations,
    ...config,
  });
};
