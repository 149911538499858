import { Pagination } from "@mantine/core";
import { flexRender } from "@tanstack/react-table";
import intersectionBy from "lodash/intersectionBy";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useCallback, useEffect, useMemo } from "react";
import { twMerge } from "tailwind-merge";

import {
  CheckboxFilter,
  convertStringArrayToCheckboxOption,
  DateFilter,
  DateRangeFilter,
  FilterGroup,
  FilterMenu,
} from "@/components/filters";
import { Header } from "@/components/header";
import { ImpersonationBanner } from "@/components/impersonationBanner";
import TableHeader from "@/components/table/tableHeader";
import { useUserContext } from "@/context/user.context";
import { useTrackAnalytics } from "@/hooks/analytics.hooks";
import { useAllScreenerLocationsQuery } from "@/hooks/queries/screener-locations.queries";
import { type Filter, useFilterState } from "@/hooks/use-filter-state.hook";
import { useTableHelper } from "@/hooks/use-table-helper.hook";
import { useUserLocalStorageState } from "@/hooks/use-user-local-storage-state.hook";
import { DateRangeOptions } from "@/types/date-range-filter";
import {
  type ScreenerLocation,
  ScreenerLocationStatus,
} from "@/types/screeners";
import {
  columnSizingHandler,
  formatScreenerLocationColumns,
  getLocationPinningStyles,
} from "@/utils/columns";
import { SEARCH_LOCAL_STORAGE_BASE_KEY } from "@/utils/local-storage";

export const ScreenersTable = () => {
  const router = useRouter();
  const track = useTrackAnalytics();
  const { user } = useUserContext();
  const {
    data: tableDataResponse,
    isLoading,
    isPending,
  } = useAllScreenerLocationsQuery();
  const { columns, tableData } = useMemo(
    () => ({
      columns: formatScreenerLocationColumns(tableDataResponse?.columns || []),
      tableData: tableDataResponse?.screenerLocations || [],
    }),
    [tableDataResponse],
  );
  const [search, setSearch, searchInitialized] =
    useUserLocalStorageState<string>(SEARCH_LOCAL_STORAGE_BASE_KEY, "");

  const handleSearch = useCallback(
    (searchValue: string, originalData: ScreenerLocation[]) => {
      const includesValue = (property?: string) =>
        property?.toLowerCase().includes(searchValue.toLowerCase());

      return originalData.filter(
        ({ internalName, jobName, locationName }) =>
          includesValue(internalName) ||
          includesValue(jobName) ||
          includesValue(locationName),
      );
    },
    [],
  );

  const {
    clearAllFilters,
    dateRange,
    filters,
    filtersInitialized,
    loadingFallback,
    pagination,
    setDateRange,
    setFilters,
    setPagination,
    table,
  } = useTableHelper<ScreenerLocation>({
    columns,
    columnPinning: {
      left: ["jobName"],
      right: [],
    },
    handleSearch,
    isLoading: isLoading || isPending,
    search: searchInitialized ? search : "",
    tableData,
    withLocalStorage: true,
  });
  const jobNameFilterOptions = useMemo(
    () => Array.from(new Set(tableData.map((location) => location.jobName))),
    [tableData],
  );
  const locationNameFilterOptions = useMemo(
    () =>
      Array.from(new Set(tableData.map((location) => location.locationName))),
    [tableData],
  );
  const [jobNameFilters, setJobNameFilters] = useFilterState<string[]>(
    filters as Filter[],
    setFilters,
    "jobName",
  );
  const [locationNameFilters, setLocationNameFilters] = useFilterState<
    string[]
  >(filters as Filter[], setFilters, "locationName");

  const validateAndUpdateFilters = useCallback(
    (
      filterOptions: string[],
      setFilter: (value: string[]) => void,
      currentFilters: string[] = [],
    ) => {
      const validFilters = intersectionBy(currentFilters, filterOptions, (v) =>
        v.toLowerCase(),
      );

      if (currentFilters.length !== validFilters.length) {
        setFilter(validFilters);
      }
    },
    [],
  );

  useEffect(() => {
    // Ensure data has been fetched and filters have been initialized from local storage before attempting to validate
    if (!loadingFallback && filtersInitialized) {
      // As a safety precaution, we check for filters that may no longer exist due to data changes.
      validateAndUpdateFilters(
        jobNameFilterOptions,
        setJobNameFilters,
        jobNameFilters,
      );
      validateAndUpdateFilters(
        locationNameFilterOptions,
        setLocationNameFilters,
        locationNameFilters,
      );
    }
  }, [
    filtersInitialized,
    jobNameFilterOptions,
    jobNameFilters,
    loadingFallback,
    locationNameFilterOptions,
    locationNameFilters,
    setJobNameFilters,
    setLocationNameFilters,
    validateAndUpdateFilters,
  ]);

  const rows = table.getFilteredRowModel().rows;

  return (
    loadingFallback || (
      <div className="w-full h-dvh">
        <Header />
        <div>
          <TableHeader />
          <div className="flex w-full">
            <FilterMenu
              clearAllFilters={() => {
                setSearch("");
                clearAllFilters();
              }}
              onSearch={setSearch}
              filters={filters}
              searchValue={search}
              rowCount={rows.length}
              searchCountName="screeners"
            >
              <FilterGroup title="Status">
                <CheckboxFilter
                  analyticsName={"Session status"}
                  key={"status"}
                  name={"status"}
                  options={convertStringArrayToCheckboxOption([
                    ScreenerLocationStatus.Live,
                    ScreenerLocationStatus.Paused,
                  ])}
                  filters={filters}
                  setFilters={setFilters}
                />
              </FilterGroup>
              <FilterGroup title="Date created">
                <DateRangeFilter
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                />
                {dateRange === DateRangeOptions.CustomRange && (
                  <>
                    <DateFilter
                      name="Created After"
                      filterName="after"
                      filters={filters}
                      setFilters={setFilters}
                    />
                    <DateFilter
                      name="Created Before"
                      filterName="before"
                      filters={filters}
                      setFilters={setFilters}
                    />
                  </>
                )}
              </FilterGroup>
              <FilterGroup title="Position">
                <CheckboxFilter
                  analyticsName={"Job name"}
                  key={"jobName"}
                  name={"jobName"}
                  options={convertStringArrayToCheckboxOption(
                    Array.from(jobNameFilterOptions),
                  )}
                  filters={filters}
                  setFilters={setFilters}
                />
              </FilterGroup>
              <FilterGroup title="Location">
                <CheckboxFilter
                  analyticsName={"Location"}
                  key={"locationName"}
                  name={"locationName"}
                  options={convertStringArrayToCheckboxOption(
                    Array.from(locationNameFilterOptions),
                  )}
                  filters={filters}
                  setFilters={setFilters}
                />
              </FilterGroup>
            </FilterMenu>
            {/* 224px width from filter menu */}
            <div className="w-[calc(100dvw_-_224px)]">
              <div
                className={twMerge(
                  "overflow-scroll",
                  "w-full",
                  user?.internal
                    ? "max-h-[calc(100dvh_-_227px)]"
                    : "max-h-[calc(100dvh_-_179px)]",
                )}
              >
                <div className="h-1" />
                <table className="w-full min-w-max border-l border-r border-gray-200 text-sm">
                  <thead className="align-start">
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr
                        key={headerGroup.id}
                        className="border-b border-gray-200"
                      >
                        {headerGroup.headers.map((header) => (
                          <th
                            colSpan={header.colSpan}
                            className="p-3 text-start uppercase font-semibold text-xs truncate"
                            key={header.id}
                            ref={(th) =>
                              columnSizingHandler(th, table, header.column)
                            }
                            style={{
                              ...getLocationPinningStyles(header.column),
                            }}
                          >
                            {header.isPlaceholder ? null : (
                              <div
                                className={
                                  header.column.getCanSort()
                                    ? "cursor-pointer select-none flex items-center gap-2"
                                    : ""
                                }
                                onClick={header.column.getToggleSortingHandler()}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                                {header.column.getIsSorted()
                                  ? {
                                      asc: (
                                        <div className="w-full flex items-center">
                                          <Image
                                            src="/assets/SortAscending.svg"
                                            width={12}
                                            height={12}
                                            alt="sort"
                                          />
                                        </div>
                                      ),
                                      desc: (
                                        <div className="w-full flex items-center">
                                          <Image
                                            src="/assets/SortDescending.svg"
                                            width={12}
                                            height={12}
                                            alt="sort"
                                          />
                                        </div>
                                      ),
                                    }[header.column.getIsSorted() as string]
                                  : header.column.getCanSort() && (
                                      <div className="w-full flex items-center">
                                        <Image
                                          src="/assets/sort.svg"
                                          width={12}
                                          height={12}
                                          alt="sort"
                                        />
                                      </div>
                                    )}
                              </div>
                            )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row) => (
                      <tr
                        key={row.id}
                        className="border-b border-[#D9D9D9] hover:bg-[#784DD6]/10"
                        onClick={() => {
                          if (row.original.sessionCount) {
                            track("Position Clicked", {
                              screener_location_id: row.id,
                            });
                            router.push(
                              `/screener-location/${row.original.id}`,
                            );
                          }
                        }}
                        style={
                          row.original.sessionCount ? { cursor: "pointer" } : {}
                        }
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            className="p-3 w-max text-sm"
                            style={{
                              ...getLocationPinningStyles(cell.column, row),
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="w-full h-16 flex justify-center items-center">
                <Pagination
                  total={Math.ceil(rows.length / pagination.pageSize)}
                  onChange={(value) =>
                    setPagination({
                      pageSize: pagination.pageSize,
                      pageIndex: value - 1,
                    })
                  }
                  color="#784DD6"
                  value={pagination.pageIndex + 1}
                />
              </div>
            </div>
          </div>
        </div>
        {user?.internal && <ImpersonationBanner />}
      </div>
    )
  );
};
